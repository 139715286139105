<template>
  <ion-page>
    <Header type="0" :title="$route.params.name" v-if="menuRole == 'phone'">
    </Header>
    <ion-content>
      <div class="moduleMenu" style="min-height: 20.75rem; overflow: hidden">
        <ModuleMenu :colNum="8" :modulesProp="modulesProp"></ModuleMenu>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import Header from "@/components/Header.vue";
import ModuleMenu from "@/views/home/ModuleMenu.vue";
import * as dd from "dingtalk-jsapi";
export default {
  name: "subPage",
  components: {
    Header,
    ModuleMenu,
  },
  data() {
    return {
      modulesProp: [],
      templateInfo: [
        {
          name: "templateInfo",
          title: "外协模具信息",
          icon: "icon-yangchexinxi",
          visible: true,
          background: "#32D5E2",
        },
        {
          name: "templateCheck/1",
          title: "外协模具点检",
          icon: "icon-shaixuan",
          visible: true,
          background: "#32D5E2",
        },
        {
          name: "templateCheck/0",
          title: "自制模具点检",
          icon: "icon-shujushaixuan",
          visible: true,
          background: "#32D5E2",
        },
      ],
      goodsList: [
        {
          name: "partsofWarehouse",
          title: "零件出库",
          icon: "icon-lingjianchuku",
          visible: true,
          background: "#3295FA",
        },
        {
          name: "partsComplement",
          title: "零件补码",
          icon: "icon-lingjianbuma",
          visible: true,
          background: "#3295FA",
        },

        {
          name: "sweepCodeOn",
          title: "扫码上架",
          icon: "icon-saomashangjia",
          visible: true,
          background: "#3295FA",
        },
        {
          name: "warehouseReceiving",
          title: "入库接收",
          icon: "icon-rukujieshou",
          visible: true,
          background: "#3295FA",
        },

        {
          name: "checkPutinStorage",
          title: "检查件入库",
          icon: "icon-jianchajianruku",
          visible: true,
          background: "#3295FA",
        },
      ],
      questionList: [
        {
          name: "questionAdd",
          title: "问题记录",
          icon: "icon-wentijilu",
          visible: true,
          background: "#3295FA",
        },
        {
          name: "question",
          title: "问题查看",
          icon: "icon-shujushaixuan",
          visible: true,
          background: "#3295FA",
        },
        {
          name: "questionManage/0",
          title: "管理类问题",
          icon: "icon-tongjifenxifeixuanzhong",
          visible: true,
          background: "#3295FA",
        },
        {
          name: "questionManage/1",
          title: "制件类问题",
          icon: "icon-okrrenwu",
          visible: true,
          background: "#3295FA",
        },
      ],
      carList: [
        {
          name: "doEntrucking",
          title: "扫码装车",
          icon: "icon-tongjifenxifeixuanzhong",
          visible: true,
          background: "#FF943E",
        },
        {
          name: "carLoaded",
          title: "扫码装车查看",
          icon: "icon-zhuangchejindu",
          visible: true,
          background: "#FF943E",
        },
      ],
      menuRole: "phone",
    };
  },
  created() {
    var _this = this;
    this.menuRole = localStorage.getItem("menuRole");
    if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: _this.$route.params.name, //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
    this.modulesByRoute();
  },
  activated() {

  },
  methods: {
    modulesByRoute() {
      const path = this.$route.params.pathName;
      const _this = this;
      var list = []
      switch (path) {
        case "templateInfo":
          list = _this.templateInfo;
          break;
        case "goods":
          list = _this.goodsList;
          break;
        case "question":
          list = _this.questionList;
          break;
        case "car":
          list = _this.carList;
          break;
      }
      var loginRole = JSON.parse(localStorage.getItem("loginRole"));
      var deviceList = [];
      for (var i = 0; i < loginRole.length; i++) {
        var list1 = list.filter((e) => {
          return loginRole[i].titleCN == e.title; //钉钉权限做完再开发
        });
        deviceList = deviceList.concat(list1);
      }
      this.modulesProp = deviceList
    },
  },
};
</script>
<style lang="scss" scoped>
.moduleMenu {
  transition: height 0.51s;
}
ion-slides {
  min-height: 15.75rem !important;
  padding-top: 15px;
}
</style>